<template>
  <Page color="info" title="Finanzen">
    <template slot="help">
      <Markdown :value="helpText" />
    </template>

    <v-container class="mb-4">
      <v-row class="align-center justify-center">
        <SchoolClassPicker outlined text v-model="schoolClass" />

        <v-spacer />

        <v-text-field
          type="search"
          dense
          single-line
          hide-details=""
          v-model="search"
          label="Schüler:in"
          append-icon="mdi-magnify"
          clearable
        />
      </v-row>
    </v-container>
    <v-card :loading="loading">
      <v-system-bar
        >Finanzen {{ schoolClass ? schoolClass.code : "" }}</v-system-bar
      >
      <v-data-table
        v-if="!$vuetify.breakpoint.xs"
        item-key="person.id"
        :headers="headers"
        hide-default-footer
        :items="itemsFiltered"
        disable-pagination
        :items-per-page="-1"
        sort-by="person"
        :item-class="
          (item) =>
            item.balance < -199.99 ? 'red lighten-4 clickable' : 'clickable'
        "
        @click:row="(item) => showDetails(item.person.id)"
      >
        <template v-slot:item.action="{ item }">
          <v-btn
            icon
            :to="{
              name: 'FinanceDetails',
              params: { id: item.person.id },
            }"
            ><v-icon>mdi-table-eye </v-icon></v-btn
          >
        </template>
        <template v-slot:item.person="{ item }">
          <PersonItem small :value="item.person" />
        </template>
        <template v-slot:item.balanceCantonal="{ item }">
          <CurrencyValue :value="item.balanceCantonal" />
        </template>
        <template v-slot:item.balanceOwn="{ item }">
          <CurrencyValue :value="item.balanceOwn" />
        </template>
        <template v-slot:footer
          ><TableFooter
            :items="itemsFiltered"
            label="Schüler:innen"
            labelSingular="Schüler:in"
        /></template>
      </v-data-table>
      <v-list v-else>
        <PersonItemBasic
          v-for="item in items"
          :value="item.person"
          :key="item.id"
          :to="{
            name: 'FinanceDetails',
            params: { id: item.person.id },
          }"
        >
          <v-chip :color="item.balance < -199.99 ? 'red lighten-4' : ''"
            ><CurrencyValue :value="item.balance"
          /></v-chip>
        </PersonItemBasic>
      </v-list>
    </v-card>
    <router-view />
  </Page>
</template>

<script>
import { defineComponent } from "vue";
import CurrencyValue from "@/components/CurrencyValue.vue";
import PersonItem from "common/components/PersonItem.vue";
import PersonItemBasic from "common/components/PersonItemBasic.vue";
import SchoolClassPicker from "@/components/SchoolClassPicker.vue";
import TableFooter from "common/components/TableFooter.vue";

import { comparePeople, searchPerson } from "common/utils/people.js";

const helpText = `
`;

export default defineComponent({
  components: {
    CurrencyValue,
    PersonItem,
    PersonItemBasic,
    SchoolClassPicker,
    TableFooter,
  },
  data() {
    return {
      helpText,
      loading: false,
      headers: [
        { text: "Schüler:in", value: "person", sort: comparePeople },
        {
          text: "Beitrag Kanton",
          value: "balanceCantonal",
          align: "end",
        },
        {
          text: "Eigene Kosten",
          value: "balanceOwn",
          align: "end",
        },
      ],
      items: [],
      search: "",
      schoolClass: null,
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (el) => !this.search || searchPerson(el.person, this.search)
      );
    },
  },
  watch: {
    schoolClass() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      if (!this.schoolClass) return;
      this.loading = true;

      this.items = await this.apiList({
        resource: "finance/personoverview",
        query: `schoolClass=${this.schoolClass.id}`,
      });
      this.loading = false;
    },
    showDetails(id) {
      this.$router.push({
        name: "FinanceDetails",
        params: { id: id },
      });
    },
  },
  created() {},
});
</script>
