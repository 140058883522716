var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Page',{attrs:{"color":"info","title":"Finanzen"}},[_c('template',{slot:"help"},[_c('Markdown',{attrs:{"value":_vm.helpText}})],1),_c('v-container',{staticClass:"mb-4"},[_c('v-row',{staticClass:"align-center justify-center"},[_c('SchoolClassPicker',{attrs:{"outlined":"","text":""},model:{value:(_vm.schoolClass),callback:function ($$v) {_vm.schoolClass=$$v},expression:"schoolClass"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"type":"search","dense":"","single-line":"","hide-details":"","label":"Schüler:in","append-icon":"mdi-magnify","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-system-bar',[_vm._v("Finanzen "+_vm._s(_vm.schoolClass ? _vm.schoolClass.code : ""))]),(!_vm.$vuetify.breakpoint.xs)?_c('v-data-table',{attrs:{"item-key":"person.id","headers":_vm.headers,"hide-default-footer":"","items":_vm.itemsFiltered,"disable-pagination":"","items-per-page":-1,"sort-by":"person","item-class":(item) =>
          item.balance < -199.99 ? 'red lighten-4 clickable' : 'clickable'},on:{"click:row":(item) => _vm.showDetails(item.person.id)},scopedSlots:_vm._u([{key:"item.action",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":"","to":{
            name: 'FinanceDetails',
            params: { id: item.person.id },
          }}},[_c('v-icon',[_vm._v("mdi-table-eye ")])],1)]}},{key:"item.person",fn:function({ item }){return [_c('PersonItem',{attrs:{"small":"","value":item.person}})]}},{key:"item.balanceCantonal",fn:function({ item }){return [_c('CurrencyValue',{attrs:{"value":item.balanceCantonal}})]}},{key:"item.balanceOwn",fn:function({ item }){return [_c('CurrencyValue',{attrs:{"value":item.balanceOwn}})]}},{key:"footer",fn:function(){return [_c('TableFooter',{attrs:{"items":_vm.itemsFiltered,"label":"Schüler:innen","labelSingular":"Schüler:in"}})]},proxy:true}],null,false,2518306107)}):_c('v-list',_vm._l((_vm.items),function(item){return _c('PersonItemBasic',{key:item.id,attrs:{"value":item.person,"to":{
          name: 'FinanceDetails',
          params: { id: item.person.id },
        }}},[_c('v-chip',{attrs:{"color":item.balance < -199.99 ? 'red lighten-4' : ''}},[_c('CurrencyValue',{attrs:{"value":item.balance}})],1)],1)}),1)],1),_c('router-view')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }